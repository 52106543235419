import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import Privacy from './components/Privacy';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
           <Route path="/privacy" element={<Privacy/>} />
          


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
