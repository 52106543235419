import React, { useEffect } from 'react';
import AOS from "aos";
import 'aos/dist/aos.css'
import prfl from "../img/prfl.svg";
import clients from "../img/clients.svg";
import hiered  from "../img/hiered.svg";
import field from "../img/field.svg";
import "../components/Style/Team.css";

export default function Team() {
    useEffect(()=>{
        AOS.init({duration:2000});
      },[]);
    return (
        <>
            <div className='team'>
                <div className='teamL'>
                    <h1>Helping all </h1>
                    <h1 className='hone'>business reinvent itself</h1>
                    <p>Adding new talent to your business</p>
                </div>
                <div className='teamR'>
                    <div className='teamuper' data-aos="fade-left">
                        <div className='flex'>
                            <img src={prfl} alt=''></img>
                            <span>
                                <h3>20</h3>
                                <p>Members</p>
                            </span>
                        </div>
                        <div className='flex'>
                            <img src={clients} alt=''></img>
                            <span>
                                <h3>56</h3>
                                <p>Clients</p>
                            </span>
                        </div>
                    </div>
                    <div className='teamuper'data-aos="fade-left">
                        <div className='flex'>
                            <img src={hiered} alt=''></img>
                            <span>
                                <h3>456</h3>
                                <p>Hired</p>
                            </span>
                        </div>
                        <div className='flex'>
                            <img src={field} alt=''></img>
                            <span>
                                <h3>10K +</h3>
                                <p>Resume Poel</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
