import React, { useEffect } from 'react';
import img from "../img/paytam.svg";
import nyka from "../img/nyka.svg";
import ed from "../img/ed.svg";
import hk from "../img/hk.svg";
import ak from "../img/ak.svg";
import sf from "../img/sf.svg";
import "../components/Style/OurClients.css";
import AOS from "aos";
import 'aos/dist/aos.css'

export default function OurClients() {

  useEffect(()=>{
    AOS.init({duration:2000});
  },[]);

  return (
    <>
    <div className='ourClients' data-aos="flip-right">
        <h1 data-aos="fade-up">Our Clients</h1>
        <p data-aos="fade-up">We have been working with some Fortune 500+ clients</p>
        <div className='clientList' data-aos="fade-up">
            <img src={img} alt=''></img>
            <img src={nyka} alt=''></img>
            <img src={ed} alt=''></img>
            <img src={hk} alt=''></img>
            <img src={ak} alt=''></img>
            <img src={sf} alt=''></img>
            {/* <img src={img} alt=''></img> */}

        </div>
    </div>
    </>
  )
}
