import React, { useEffect } from 'react';
import AOS from "aos";
import 'aos/dist/aos.css'
import "../components/Style/BlinkHire.css";
import blink from "../img/blink.svg";

export default function BlinkHire() {
    useEffect(()=>{
        AOS.init({duration:2000});
      },[]);
    return (
        <>
            <div className='blinkHire' id='blinkHire'>
                <h1 data-aos="fade-right">Blink Hire</h1>
                <div className='blink' >
                    <div className='blinkL' data-aos="fade-right">
                        <img src={blink} alt=''></img>
                    </div>
                    <div className='blinkR'>
                        <h4>Blink Hire: Streamline Process with us</h4>
                        <p>Discover a groundbreaking hiring platform at SpyGlass which makes finding the perfect candidates effortless.</p>
                        <p>Our AI-powered technology matches your job requirements with the best talent, saving you time and effort.</p>
                        <p>Communicate seamlessly with candidates through our integrated WhatsApp feature, ensuring convenient engagement. </p>
                        <p>With a vast database of diverse candidates and an intuitive dashboard, managing your hiring process is a breeze. </p>
                        <p>Gain valuable insights with smart analytics and simplify onboarding with our streamlined process. Join us today and transform your talent acquisition strategy with ease.</p>
                        <button>Let’s Hire</button>

                    </div>
                </div>
            </div>
        </>
    )
}
