import React from 'react';
import "../components/Style/Footer.css";
import media from "../img/Social Links.svg";
import linkedin from "../img/linkedin/Footer/Big/Social Icons.svg";
import send from "../img/send.svg";
import logo from "../img/logo.svg";
import { Link } from 'react-router-dom';


export default function Footer() {
    return (
        <>
            <div className='footer' id='footer'>
                <div className='footerL'>
                   <img src={logo} alt=''></img>
                    <span>
                        <p>Copyright © 2017 SpyGlass ltd.</p>
                        <p>All rights reserved</p>
                    </span>
                    <img src={linkedin}  className='linkedIn' alt=''></img>
                </div>
                <div className='footerR'>
                <div className='uptoDate'>
                    <h1>Contact Us</h1>
                        <p>Email : Info@spyglassanalyticssoftware.com</p>
                        <p>Phone : 09625784325</p>
                        <p>Address : Ground Floor, Tower B, Building 5, DLF Cyber City, Phase 3, Gurugram, Haryana 122002, IN</p>
                       
                    </div>
                    <div className='comPany'>
                        <h1>Company</h1>
                        <ul>
                            <li>About</li>
                            {/* <li>Blog</li> */}
                            <li>Contact Us</li>
                            {/* <li>Pricing</li> */}
                            {/* <li>About</li> */}
                        </ul>
                    </div>
                    <div className='supPort'>
                        <h1>Support</h1>
                        <ul>
                            {/* <li>Help Center</li> */}
                            <li>Term Of Service</li>
                            {/* <li>Legal</li> */}
                          <Link to="/privacy"><li>Privacy Policy</li></Link>  
                            {/* <li>Status</li> */}
                        </ul>
                    </div>
                   
                </div>
            </div>

        </>
    )
}
