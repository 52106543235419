import React from 'react';
import "../components/Style/Navbar.css";
import logo from "../img/logo.svg";
import cross from "../img/close.svg";
import navArrow from "../img/navArrow.svg";
// import { Link } from 'react-router-dom';


export default function Navbar() {
  const open = ()=>{
    document.getElementById("open").style.width = "40%";
    // document.getElementById("close").style.width = "0";

  }
  const close = ()=>{
    // document.getElementById("close").style.width = "100%";
    document.getElementById("open").style.width = "0";

  }
  return (
    <>
      <div className='mainNav'>
        <img src={logo} alt=''></img>

          <img src={navArrow} alt='' onClick={open} id='close'></img>  
        <div className='navArrow'  id='open'>      
          <div className='sideMenu' id='sideMenu'>
            <img src={cross} alt='' className='cross' onClick={close}></img>
          <ul>
            <a href="/"><li>Home</li></a>
            <a href="/#blinkHire"> <li>Product</li></a>
            <a href="#OurService"><li>Services</li></a>
            {/* <li>Testimonial</li> */}
            <a href="#getDemo"> <li>FAQ</li></a>
            <a href="#footer"> <li>Contact Us</li></a>

          </ul>
          </div>
        </div>


        <div className='navList'>
          <ul>
            <a href="/"><li>Home</li></a>
            <a href="/#blinkHire"> <li>Product</li></a>
            <a href="/#OurService"><li>Services</li></a>
            {/* <li>Testimonial</li> */}
            <a href="/#getDemo"> <li>FAQ</li></a>
            <a href="/#footer"> <li>Contact Us</li></a>

          </ul>
        </div>
      </div>
    </>
  )
}
