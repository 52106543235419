import React, { useEffect } from 'react';
import AOS from "aos";
import 'aos/dist/aos.css'
import "../components/Style/Platform.css";
import ai from "../img/ai.svg";
import traking from "../img/tracking.svg";
import Driven from "../img/Driven.svg";
import process from "../img/process.svg";
import whatsapp from "../img/whatsapp.svg";
import extensive from "../img/extensive.svg";


export default function Platform() {
    useEffect(()=>{
        AOS.init({duration:2000});
      },[]);
    return (
        <>
            <div className='platForm'>
                <h1 data-aos="fade-up">Best platform for hiring most effectively</h1>
                <div className='platformU' >
                <div className='platformCard'>
                        <img src={ai} alt='' data-aos="fade-up"></img >
                        <h5 data-aos="fade-up">Extensive Candidate Database</h5>
                        <p data-aos="fade-up">Access our vast candidate database with a diverse pool of talent from various industries and skill sets.
                        </p>
                    </div>
                    <div className='platformCard'>
                        <img src={whatsapp} alt=' data-aos="fade-up"'></img>
                        <h5 data-aos="fade-up">AI-Powered Candidate Matching</h5>
                        <p data-aos="fade-up">Our platform utilizes powerful artificial intelligence algorithms to match your job requirements with the most suitable candidates.</p>
                    </div>
                    <div className='platformCard'>
                        <img src={extensive} alt='' data-aos="fade-up"></img>
                        <h5 data-aos="fade-up">WhatsApp Integration</h5>
                        <p data-aos="fade-up">Connect with candidates instantly and conveniently using our seamless WhatsApp integration.</p>
                    </div>
                   

                </div>
                <div className='platformD'>
                    <div className='platformCard'>
                        <img src={traking} alt='' data-aos="fade-up"></img>
                        <h5 data-aos="fade-up">Intuitive Dashboard and Applicant Tracking</h5>
                        <p data-aos="fade-up">Manage your hiring process efficiently with our user-friendly dashboard and integrated applicant tracking system.
                        </p>
                    </div>
                    <div className='platformCard'>
                        <img src={Driven} alt='' data-aos="fade-up"></img>
                        <h5 data-aos="fade-up">Smart Analytics for
                            Data-Driven Decisions</h5>
                        <p data-aos="fade-up">Gain valuable insights into your recruitment efforts with comprehensive analytics and reporting features.</p>
                    </div>
                    <div className='platformCard'>
                        <img src={process} alt='' className='process' data-aos="fade-up"></img>
                        <h5 data-aos="fade-up">Streamlined Onboarding Process</h5>
                        <p data-aos="fade-up">Simplify onboarding of new hires with our  process that includes document management and task assignments.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
