// import React from 'react';
import React, { useEffect } from 'react';
import AOS from "aos";
import 'aos/dist/aos.css'
import plan from "../img/planing.svg";
import outcome from "../img/outcome.svg";
import source from "../img/sourcing.svg";
import filter from "../img/filter.svg";
import "../components/Style/OurService.css";

export default function OurService() {
    useEffect(()=>{
        AOS.init({duration:2000});
      },[]);
  return (
   <>
   <div className='OurService' id='OurService'>
    <h1 data-aos="fade-up">How We Provide You Best Service There Is</h1>
    <p data-aos="fade-up">Get to Know How</p>
    <div className='service' data-aos="fade-up">
<div className='serviceContent'>
    <img src={plan} alt=''></img>
    <h1>Planing</h1>
    <p>Our dedicated account manager understands client requirements and collaborates with our technical and recruitment teams to strategize resume sourcing.</p>
    
</div>
<div className='serviceContent'>
    <img src={outcome} alt=''></img>
    <h1>Outcome</h1>
    <p>We share screened resume pipeline with the client and gather their feedback. We carefully analyze and incorporate the client's feedback into our recruitment process.</p>

</div>
<div className='serviceContent'>
    <img src={source} alt=''></img>
    <h1>Sourcing</h1>
<p>Our recruitment team leverages an <span style={{color:"green",fontFamily:"cursive",fontWeight:"800"}}>AI-Enabled</span> engine to source candidates from diverse channels. Initial sourcing is based on a predefined strategy.</p>

</div>
<div className='serviceContent'>
    <img src={filter} alt=''></img>
    <h1>Filtering</h1>
    <p>Our technical team performs a second level of screening based on client requirements. We then share the screened resume pipeline with the client and gather their feedback.</p>

</div>
    </div>
   </div>
   </>
  )
}
