import React from 'react';
import "../components/Style/Regarding.css";
// import tick from "../img/tick.svg";
import technical from "../img/technical.svg";
import experts from "../img/experts.svg";
import hand from "../img/hand.svg";
import exposure from "../img/exposure.svg";

export default function Technical() {
  return (
    <>
    <div className='regarding' >
        

        
        <div className='regardR' style={{paddingLeft:"100px"}}>
            <h1>Technical Team</h1>
            <p>Unique and powerful suite of software to run your entire business, brought to you by a company with the long term vision to transform the way you work.</p>
            <div className='regardGroup'>
                <div className='regardSubGrp'>
                    <img src={experts} alt=''></img>
                    <h4>Industry Experts</h4>
                    <p>Consiste of Industry Expert in various domains</p>
                    
                </div>
                <div className='regardSubGrp'>
                    <img src={hand} alt=''></img>
                    <h4>Hand-on Experience</h4>
                    <p>Have good hands-on expperience on latest technologies</p>

                </div>
            </div>
          
            <div className='regardGroup'>
                <div className='regardSubGrp'>
                    <img src={exposure} alt=''></img>
                    <h4>Exposure</h4>
                    <p>Have exposure to both startup and MNC environment</p>
                    
                </div>
               
            </div>
            {/* <button>Let’s Hire</button> */}
        </div>
        <div className='regardL'>
            <img src={technical} alt=''></img>

        </div>
    </div>
    </>
  )
}
