import React from 'react';
import "../components/Style/Regarding.css";
// import tick from "../img/tick.svg";
import regard from "../img/regard.svg";
import female from "../img/female.svg";
import tech from "../img/tech.svg";
import impresive from "../img/impresive.svg";
import handling from "../img/handling.svg";
import versatile from "../img/versatile.svg";


export default function Regarding() {
  return (
    <>
    <div className='regarding' >
        {/* <div className='regard'>
            <h1>Regarding Team</h1>
            <span className='flex'>
                <img src={tick} alt=''></img>
                <p>Female who has vast recruitment experience and have taken break and now starting their 2nd inning in the career</p>
            </span>
            <span className='flex'>
                <img src={tick} alt=''></img>
                <p>Experience in recruitment across various levels in Tech/Non tech domain</p>
            </span>
            <span className='flex'>
                <img src={tick} alt=''></img>
                <p>Expert in hiring through social media, job portals</p>
            </span>
            <span className='flex'>
                <img src={tick} alt=''></img>
                <p>Good Exposure to handle end to end recruitment cycle</p>
            </span>
            <span className='flex'>
                <img src={tick} alt=''></img>
                <p>Our team has good experience in delivering startup and MNC environment</p>
            </span>
        </div>
        <div className='technical'>
            <h1>Technical Team</h1>
            <span className='flex'>
                <img src={tick} alt=''></img>
                <p>Consist of Industry Experts like product managers, software architects, sales managers, marketing managers</p>
            </span>
            <span className='flex'>
                <img src={tick} alt=''></img>
                <p>Has good hands-on experience on latest technologies </p>
            </span>
            <span className='flex'>
                <img src={tick} alt=''></img>
                <p>Has exposure to both startup and MNC environment and handled various small to large scale projects </p>
            </span>
        </div> */}

        <div className='regardL'>
            <img src={regard} alt=''></img>

        </div>
        <div className='regardR' style={{paddingRight:"100px"}}>
            <h1>Regarding Team</h1>
            <p>Unique and powerful suite of software to run your entire business, brought to you by a company with the long term vision to transform the way you work.</p>
            <div className='regardGroup'>
                <div className='regardSubGrp'>
                    <img src={female} alt=''></img>
                    <h4>Female Driven</h4>
                    <p>Female who has-vast recruitment experience and have taken break and now starting their 2nd inning in the career</p>
                    
                </div>
                <div className='regardSubGrp'>
                    <img src={tech} alt=''></img>
                    <h4>Tech/Non Tech Domains</h4>
                    <p>Experience in recruitment across carious levels in Tech/Non tech domain</p>

                </div>
            </div>
            <div className='regardGroup'>
                <div className='regardSubGrp'>
                    <img src={versatile} alt=''></img>
                    <h4>Versatile</h4>
                    <p>Expert in hiring through various social media, job protals</p>
                    
                </div>
                <div className='regardSubGrp'>
                    <img src={handling} alt=''></img>
                    <h4>Great Handling</h4>
                    <p>Good exposure to handle end to end recruitment cycle</p>

                </div>
            </div>
            <div className='regardGroup'>
                <div className='regardSubGrp'>
                    <img src={impresive} alt=''></img>
                    <h4>Impressive TAT</h4>
                    <p>Our team has good experience in delievering startup and MNC environment with impressive TAT</p>
                    
                </div>
               
            </div>
            {/* <button>Let’s Hire</button> */}
        </div>
    </div>
    </>
  )
}
