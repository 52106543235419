import React from 'react';
import "../components/Style/HeroSection.css";
import img from "../img/heroImg.svg";

export default function HeroSection() {
    return (
        <>
            <div className='mainHero'>
                <div className='heroL'>
                    <h1>Empowering</h1>
                    <h1 className='hone'> Careers and Company</h1>
                    <p>Let us do the hire you</p>
                    <button>Contact Us</button>
                </div>
                <div className='heroR'>
                    <img src={img} alt=''></img>
                </div>
            </div>
        </>
    )
}
