import React from 'react';
import Navbar from './Navbar';
import HeroSection from './HeroSection';
import OurClients from './OurClients';
import OurService from './OurService';
import Team from './Team';
import BlinkHire from './BlinkHire';
import Platform from './Platform';
import Regarding from './Regarding';
// import Testimonials from './components/Testimonials';
import GetDemo from './GetDemo';
import Footer from './Footer';
// import ContactUs from './ContactUs';
// import Privacy from './Privacy';
import Technical from './Technical';

export default function Home() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <OurClients />
      <OurService />
      <Team />
      <BlinkHire />
      <Technical/>
      <Regarding />
      <Platform />
      {/* <Testimonials /> */}
      {/* <ContactUs /> */}
      <GetDemo />
      <Footer />
      {/* <Privacy /> */}
    </>
  )
}
