import React from 'react';
import "../components/Style/GertDemo.css";

export default function GetDemo() {
  return (
   <>
   <div className='getDemo' id='getDemo'>
    <h1>Try Blink Hire
To Build Team With Talent</h1>
<button>Contact Us</button>
   </div>
   </>
  )
}
